<template>
  <EbzApp ref="appRef">
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <KeepAlive>
          <Suspense @resolve="loading = false">
            <template #default>
              <component :is="Component"></component>
            </template>
          </Suspense>
        </KeepAlive>
      </template>
    </RouterView>

    <!-- Add loading overlay on SSR build -->
    <Transition name="ebz-transition-fade">
      <div
        v-if="showLoading"
        class="!fixed z-9999 w-100vw h-100vh top-0 left-0 bg-white flex items-center justify-center"
      >
        <EbzLoading size="6rem" :desc="t('general.loading_page')"></EbzLoading>
      </div>
    </Transition>
  </EbzApp>
</template>

<script setup lang="ts">
import { EbzApp } from "./components/EbzApp";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { useI18nMessages } from "./composables/useI18nMessages";
import { localize, type PartialI18nDictionary } from "@vee-validate/i18n";
import { findFirstFocusableElement } from "./common/focus";
import { useHead, useSeoMeta } from "@unhead/vue";
import {
  useSchemaOrg,
  defineWebSite,
  defineWebPage,
  defineOrganization,
} from "@vueuse/schema-org";
import ogImage from "@/assets/img/thumb.png?width=1200&height=630&fit=cover&position=center&format=jpg&imagetools";
import ogImageMeta from "@/assets/img/thumb.png?width=1200&height=630&fit=cover&position=center&format=jpg&meta=width;height&imagetools_meta";
import logoImage from "@/assets/img/logotipo_h.png?width=1024&format=png&imagetools";
import logoMeta from "@/assets/img/logotipo_h.png?width=1024&height=1024&fit=contain&position=center&background=transparent&format=png&meta=width;height&imagetools_meta";
import { useSocialLinks } from "./composables/useSocialLinks";
import { EbzLoading } from "./components/EbzLoading";
import { useGsap } from "@/composables/useGsap";
import { isClient } from "@vueuse/core";
import { useAppStore } from "./stores/app";
import { absoluteUrl } from "./common/url";

const { t } = useI18n({ useScope: "global" });

const gsap = useGsap();
gsap.registerPlugin(ScrollTrigger);

const { locale } = useI18n();
const router = useRouter();
const route = useRoute();
const enter = ref(false);
const loading = ref(true);
const showLoading = computed(() => !isClient || !enter.value || loading.value);
const appStore = useAppStore();

// Delay page transition
const enterDelay = 0.75; // 0.75s
onMounted(() => {
  gsap.delayedCall(enterDelay, () => {
    enter.value = true;
  });
});

watch(
  () => loading.value,
  (isLoading) => {
    if (!isLoading) {
      requestAnimationFrame(() => {
        ScrollTrigger.refresh();
        appStore.loaded.value = true;
      });
    }
  }
);

// Load custom vee-validate messages
const validatorMessages =
  useI18nMessages<PartialI18nDictionary>("form.validate");
watch(
  () => validatorMessages.value,
  (messages) => {
    localize(locale.value, messages);
  },
  { immediate: true }
);

// Focus element on route change
const appRef = ref<InstanceType<typeof EbzApp>>();
router.afterEach((to, from) => {
  if (to.hash === from.hash) return;

  if (to.hash && to.hash !== "#") {
    const el = document.querySelector(to.hash);
    const focusableEl = el && findFirstFocusableElement(el);
    if (focusableEl) {
      focusableEl.focus({
        preventScroll: true,
      });
    }
    return;
  } else if (from.hash && from.hash !== "#") {
    const rootEl = appRef.value?.$el;
    const focusableEl = rootEl && findFirstFocusableElement(rootEl);
    if (focusableEl) {
      focusableEl.focus({
        preventScroll: true,
      });
    }
  }
});

// Meta Tags
useHead(() => ({
  htmlAttrs: {
    lang: locale.value,
  },
  link: [
    {
      rel: "canonical",
      href: absoluteUrl(route.path),
    },
  ],
}));

// Global SEO
useSeoMeta(() => ({
  title: t("head.title"),
  ogTitle: t("head.title"),
  description: t("head.description"),
  ogDescription: t("head.description"),
  twitterCard: "summary_large_image",
  ogLocale: locale.value,
  ogType: "website",
  ogUrl: absoluteUrl(),
  ogSiteName: t("company.name"),
  ogImage: absoluteUrl(ogImage),
  ogImageSecureUrl: absoluteUrl(ogImage),
  ogImageWidth: ogImageMeta.width,
  ogImageHeight: ogImageMeta.height,
  ogImageType: "image/jpeg",
  ogImageAlt: t("head.image_alt"),
  twitterSite: t("head.twitter_site"),
}));

// Global SEO schema
const socialLinks = useSocialLinks();

useSchemaOrg([
  defineWebSite({
    name: () => t("head.title"),
  }),
  defineWebPage(),
  defineOrganization({
    name: () => t("company.name"),
    logo: () => ({
      url: logoImage,
      width: logoMeta.width,
      height: logoMeta.height,
    }),
    email: () => t("company.email"),
    sameAs: () => socialLinks.value.map(({ url }) => url),
  }),
]);
</script>

<style lang="scss">
.ebz-transition-page {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.25s var(--ebz-easing-base);
  }

  &-leave-to,
  &-enter-from {
    opacity: 0;
  }

  &-enter-to,
  &-leave-from {
    opacity: 1;
  }
}
</style>
