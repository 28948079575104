import { tryOnScopeDispose } from "@vueuse/core";
import { gsap } from "gsap";

function isContext(value: any): value is gsap.Context {
  return (
    value &&
    typeof value === "object" &&
    "revert" in value &&
    typeof value["revert"] === "function"
  );
}

export function useGsap() {
  const animations = new Set<gsap.core.Animation | gsap.Context>();

  const _gsap = new Proxy(gsap, {
    get(target, p, receiver) {
      const result = Reflect.get(target, p, receiver);

      // Trap function calls to get created Tween/Timeline instances
      if (typeof result === "function") {
        return new Proxy(result, {
          apply(target, thisArg, argArray) {
            const fnReturn = Reflect.apply(target, thisArg, argArray);

            // Track created animation instances to kill them on scope dispose
            if (
              fnReturn instanceof gsap.core.Animation ||
              isContext(fnReturn)
            ) {
              animations.add(fnReturn);
            }

            return fnReturn;
          },
        });
      }

      return result;
    },
  });

  tryOnScopeDispose(() => {
    animations.forEach((anim) => anim.kill());
    animations.clear();
  });

  return _gsap;
}
