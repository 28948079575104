export function getScrollParent(el?: HTMLElement) {
  while (el) {
    if (hasScrollbar(el)) return el;
    el = el.parentElement!;
  }

  return document.scrollingElement as HTMLElement;
}

export function hasScrollbar(el?: Element | null) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return false;

  const style = window.getComputedStyle(el);
  return (
    style.overflowY === "scroll" ||
    (style.overflowY === "auto" && el.scrollHeight > el.clientHeight)
  );
}

export function getScrollParents(el?: Element | null, stopAt?: Element | null) {
  const elements: HTMLElement[] = [];

  if (stopAt && el && !stopAt.contains(el)) return elements;

  while ((el = el?.parentElement)) {
    if (hasScrollbar(el)) elements.push(el as HTMLElement);
    if (el === stopAt) break;
  }

  return elements;
}

export function getScrollbarWidth(el?: HTMLElement) {
  if (!el) {
    var inner = document.createElement("p");
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement("div");
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = "scroll";
    var w2 = inner.offsetWidth;

    if (w1 == w2) {
      w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return w1 - w2;
  }
  return el.offsetWidth - el.clientWidth;
}
