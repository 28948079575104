export function findFirstFocusableElement(container: Element) {
  return Array.from(container.getElementsByTagName("*")).find(isFocusable);
}

export function isFocusable(item: Element): item is HTMLElement {
  const tabIndex = Number(
    (item as HTMLElement).tabIndex ?? item.getAttribute("tabindex")
  );
  if (!isNaN(tabIndex) && tabIndex >= 0) {
    return true;
  }

  switch (item.tagName.toLowerCase()) {
    case "a":
      return !!(item as HTMLAnchorElement).href;
    case "input":
      return (
        (item as HTMLInputElement).type !== "hidden" &&
        !(item as HTMLInputElement).disabled
      );
    case "select":
    case "textarea":
    case "button":
      return !(item as HTMLButtonElement).disabled;
    default:
      return false;
  }
}
