import { useAppStore } from "@/stores/app";
import type { RouterOptions } from "vite-ssg";
import type { RouterScrollBehavior } from "vue-router";

const routerOptions: RouterOptions = {
  routes: [
    {
      path: "/",
      component: () => import("@/layouts/HomeLayout.vue"),
    },
    {
      path: "/:match(.*)*",
      redirect: "/",
    },
  ],
  scrollBehavior(to, _, savedPosition) {
    const { waitLoaded } = useAppStore();

    return waitLoaded().then(() => {
      let target: ReturnType<RouterScrollBehavior> = {};

      if (savedPosition) {
        target = { ...savedPosition, behavior: "auto" };
      } else if (to.hash) {
        target = { el: to.hash };
      } else {
        target = Object.assign<ScrollToOptions, ScrollToOptions>(
          { top: 0, left: 0 },
          (to.meta.scroll ?? {}) as ScrollToOptions
        );
      }

      return {
        behavior: "smooth",
        ...target,
      };
    });
  },
};

export default routerOptions;
