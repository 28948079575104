import { defineRule, configure } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";
import { required, email, min, max } from "@vee-validate/rules";
import ptBR from "@vee-validate/i18n/dist/locale/pt_BR.json";
import "./rules";

const rules = {
  required,
  email,
  min,
  max,
};

Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule as keyof typeof rules]);
});

configure({
  generateMessage: localize({
    "pt-BR": ptBR,
  }),
});

setLocale("pt-BR");
