<template>
  <component class="ebz-rgb relative z-0" :is="tag">
    <div class="ebz-rgb__bg absolute top-0 left-0 w-full h-full z--1">
      <canvas
        class="w-full h-full z-0"
        aria-hidden="true"
        ref="canvas"
        width="36"
        height="36"
      ></canvas>
      <div class="ebz-rgb__noise absolute w-full h-full left-0 top-0 z-1"></div>
    </div>

    <slot></slot>
  </component>
</template>

<script lang="ts">
export const makeEbzRgbProps = propsFactory(
  {
    tag: {
      type: [String, Object] as PropType<string | Component>,
      default: "div",
    },
    speed: {
      type: Number,
      default: 0.175,
    },
    offsetX: {
      type: Number,
      default: 1,
    },
    offsetY: {
      type: Number,
      default: 1,
    },
    zoom: {
      type: Number,
      default: 30,
    },
    colors: {
      type: Array as PropType<ColorInput[]>,
      default: () => [
        theme.colors.mediumSpringGreen,
        theme.colors.blueRYB,
        theme.colors.magenta,
      ],
    },
    fps: {
      type: Number,
      default: 24,
    },
  },
  "ebz-rgb"
);

export interface EbzRgbState extends Pausable {}

const EBZ_RGB = Symbol("EbzRgb") as InjectionKey<Ref<EbzRgbState>>;
</script>

<script setup lang="ts">
import { propsFactory } from "@/common/props";
import {
  computed,
  inject,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watchEffect,
  type Component,
  type InjectionKey,
  type PropType,
  type Ref,
} from "vue";
import { createNoise3D } from "simplex-noise";
import {
  useElementSize,
  useRafFn,
  useThrottleFn,
  type Pausable,
} from "@vueuse/core";
import { colorScale, type ColorInput } from "@/common/color";
import { theme } from "@/theme";

const props = defineProps(makeEbzRgbProps());

const noise3D = createNoise3D();
const canvas = ref<HTMLCanvasElement>();
const currentState = ref(null!) as Ref<EbzRgbState>;
const parentState = inject(EBZ_RGB, null);
const palette = computed(() => colorScale(props.colors));

provide(EBZ_RGB, currentState);

if (parentState) {
  watchEffect(() => {
    if (parentState.value.isActive) {
      parentState.value.pause();
    }
  });

  onUnmounted(() => {
    parentState.value.resume();
  });
}

onMounted(() => {
  const canvasEl = canvas.value!;
  const ctx = canvasEl.getContext("2d")!;
  const imageData = ctx.getImageData(0, 0, canvasEl.width, canvasEl.height);
  const data = imageData.data;

  const { width: elWidth, height: elHeight } = useElementSize(canvas, {
    width: canvasEl.clientWidth,
    height: canvasEl.clientHeight,
  });
  const ratio = computed(() => elWidth.value / Math.max(1, elHeight.value));

  let time = 0;
  const { pause, isActive, resume } = useRafFn(
    useThrottleFn(
      () => {
        const { width, height } = canvasEl;

        for (let x = 0; x < width; x++) {
          for (let y = 0; y < height; y++) {
            const factor =
              noise3D(
                (x * ratio.value + props.offsetX) / Math.max(1, props.zoom),
                (y + props.offsetY) / Math.max(1, props.zoom),
                time / 32
              ) *
                0.5 +
              0.5;
            const [r, g, b] = palette.value(factor);
            data[(x + y * width) * 4 + 0] = r;
            data[(x + y * width) * 4 + 1] = g;
            data[(x + y * width) * 4 + 2] = b;
            data[(x + y * width) * 4 + 3] = 255;
          }
        }

        ctx.putImageData(imageData, 0, 0);
        time += props.speed;
      },
      () => 1000 / props.fps
    ),
    { immediate: true }
  );

  currentState.value = {
    pause,
    resume,
    isActive,
  };
});
</script>

<style lang="scss">
.ebz-rgb {
  &__noise {
    background: url("@/assets/img/noise.webp");
    background-position: 0 0;
    background-size: auto auto;
    background-repeat: repeat;
  }
}
</style>
