import type { UserModule } from "@/types";
import { SchemaOrgUnheadPlugin } from "@vueuse/schema-org";

export const install: UserModule = ({ head, router }) => {
  head!.use(
    SchemaOrgUnheadPlugin(
      {
        host: import.meta.env.VITE_HOST,
        dateModified: __BUILD_DATE__,
      },
      () => {
        const route = router.currentRoute.value;
        return {
          path: route.path,
          ...route.meta,
        };
      }
    )
  );
};
