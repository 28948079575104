import { resolveUnref, type MaybeComputedRef } from "@vueuse/core";
import { computed, type ComputedRef } from "vue";
import { useI18n, type UseI18nOptions } from "vue-i18n";

export function useI18nMessages<
  T,
  Options extends UseI18nOptions = UseI18nOptions
>(key?: MaybeComputedRef<string>, options?: Options): ComputedRef<T> {
  const { locale, tm, rt, getLocaleMessage } = useI18n(options);

  function resolve(object: any): any {
    if (object != null) {
      switch (typeof object) {
        case "string":
        case "function":
          return rt(object);
        case "object":
          if (Array.isArray(object)) {
            return object.map(resolve);
          }
          return Object.fromEntries(
            Object.entries(object).map(([key, value]) => [key, resolve(value)])
          );
      }
    }
    return object;
  }

  return computed(() => {
    const _key = resolveUnref(key);
    const messages = (_key ? tm(_key) : getLocaleMessage(locale.value)) as any;
    return resolve(messages);
  });
}
