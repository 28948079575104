<template>
  <svg
    class="ebz-loading"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 828 828"
    role="img"
    :aria-labelledby="ariaLabelledBy"
    :id="id"
    :width="width"
    :height="height"
    :style="{
      fontSize: coercePixel(size),
    }"
  >
    <title :id="titleId">{{ ariaTitle }}</title>
    <desc v-if="desc" :id="descId">{{ desc }}</desc>

    <defs>
      <mask id="ebz-loading-mask" maskUnits="userSpaceOnUse">
        <g fill="white">
          <path
            d="M680.657 356.219c5.484-11.576 10.256-22.94 16.095-33.728 17.667-32.64 39.6-61.933 69.805-84.168 15.128-11.137 30.948-21.33 46.193-32.316 4.628-3.334 8.215-2.19 11.98.636 3.971 2.979 3.763 6.894 1.355 10.604-8.183 12.62-16.187 25.379-24.933 37.603-9.524 13.314-21.07 25.33-29.467 39.272-14.66 24.346-28.48 49.3-40.961 74.826-10.579 21.635-10.645 45.207-7.792 68.907 1.73 14.36-2.16 27.796-11.547 39.045-4.593 5.507-17.618 6.608-23.384 2.219-8.62-6.564-17.249-13.2-25.218-20.52-21.21-19.483-42.457-38.959-62.943-59.194-14.956-14.773-28.525-30.938-43.08-46.131a1100.028 1100.028 0 0 0-37.648-37.463c-6.794-6.437-9.61-14.207-10.162-23.023-.716-11.446 6.604-18.918 18.13-18.745 9.518.143 17.667 3.475 24.092 10.78 22.37 25.432 44.724 50.879 67.27 76.155 8.253 9.253 16.988 18.082 25.594 27.017 1.48 1.536 3.414 2.634 7.032 5.362-.347-4.505-.22-7.137-.793-9.606-5.968-25.679-17.601-49.035-30.03-72.017-6.102-11.287-12.83-22.236-19.12-33.424-21.117-37.556-52.9-63.297-91.533-80.75-21.35-9.645-44.653-13.235-67.815-15.091-47.71-3.822-94.6.374-139.133 19.554-24.632 10.608-48.186 23.74-64.51 45.447-20.913 27.804-40.4 56.538-49.233 91.313-7.248 28.541-15.637 56.786-16.726 86.377-.327 8.865 1.756 17.968 3.75 26.73 2.6 11.411-2.653 18.018-14.022 16.395-14.454-2.062-25.447-14.738-27.147-30.888-2.32-22.026-.217-43.413 5.09-65.071 8.353-34.084 18.266-67.508 34.65-98.567 13.181-24.981 26.048-50.26 47.28-70.09 32.205-30.084 70.253-47.646 113.168-56.198 27.59-5.498 55.358-7.833 83.303-6.182 69.662 4.11 130.776 28.078 179.097 79.968 16.335 17.54 30.679 36.686 41.984 57.947 13.287 24.992 26.583 49.978 39.885 74.96.276.52.676.972 1.444 2.055"
          />
          <path
            d="M236.59 552.476c2.8 4.084 5.069 8.685 8.482 12.17 34.948 35.694 74.522 65.374 121.73 82.19 33.583 11.965 68.861 11.534 103.23.28 60.43-19.786 112.759-52.132 153.858-101.192 8.477-10.119 15.386-21.55 23.408-32.93 9.14 8.665 18.722 17.456 27.8 26.74 1.053 1.075.157 5.582-1.273 7.308-14.249 17.198-27.449 35.566-43.553 50.866-33.026 31.38-71.324 55.433-113.917 71.903-49.464 19.131-100.258 25.707-152.445 12.318-21.894-5.616-41.418-16.694-60.56-28.224-14.973-9.022-29.584-18.713-43.834-28.837-10.08-7.16-19.288-15.538-28.883-23.375-1.63-1.332-3.246-2.683-6.576-5.433 0 5.626-.253 9.278.04 12.888 3.17 39.169-4.644 76.773-17.525 113.32-9.804 27.813-23.696 53.813-40.683 78.034-1.326 1.89-3.338 3.674-5.434 4.584-6.492 2.814-11.476-.38-10.678-7.296 1.52-13.157 3.387-26.304 5.832-39.32 3.135-16.693 7.916-33.12 10.31-49.901 3.682-25.826 6.937-51.796 8.42-77.816.872-15.307-.599-31.12-3.663-46.168-2.813-13.815-8.141-27.379-18.173-38.304-1.524-1.661-2.25-4.327-2.694-6.652-1.117-5.863-1.746-11.817-2.786-17.697-2.244-12.68 4.21-21.244 13.617-28.11 2.067-1.507 5.567-2.067 8.173-1.64 12.627 2.058 25.359 3.891 37.715 7.102 46.453 12.074 92.538 25.406 137.756 41.632 14.718 5.28 29.817 9.5 44.707 14.306 10.227 3.302 14.947 11.82 17.211 21.103 2.017 8.277-4.413 13.307-10.693 17.49-10.732 7.146-21.619 7.346-33.324 1.826-39.9-18.825-81.735-32.341-124.053-44.455-.516.43-1.03.86-1.543 1.29"
          />
        </g>
      </mask>
    </defs>

    <g role="presentation" mask="url(#ebz-loading-mask)">
      <path
        pathLength="100"
        d="M 160.446 451.288 C 160.446 451.288 146.119 419.077 160.44 368.211 C 175.19 315.821 202.511 255.889 223.4 232.747 C 244.288 209.605 269.968 191.2 291.068 182.984 C 320.77 171.419 346.523 162.663 372.463 161.267 C 398.403 159.87 430.42 156.655 465.714 165.83 C 501.009 175.004 553.561 198.799 571.422 216.829 C 590.236 235.82 626.662 289.582 642.697 318.07 C 658.188 345.593 697.62 461.101 697.62 461.101 L 646.429 421.962 L 528.124 292.953 L 693.237 478.551 C 693.237 478.551 702.585 469.309 706.481 441.114 C 709.764 417.355 697.69 390.464 699.834 362.181 C 702.044 333.013 716.139 393.866 715.71 368.78 C 715.561 360.031 701.53 312.016 720.822 335.625 C 738.442 357.188 715.365 300.346 730.416 312.798 C 740.947 321.511 764.019 311.117 741.942 297.035 C 722.551 284.666 777.559 297.41 758.735 282.172 C 744.75 270.852 760.072 273.675 772.706 261.582 C 789.779 245.241 813.514 219.534 813.514 219.534"
      />
      <path
        pathLength="100"
        d="M 657.529 532.457 C 657.529 532.457 608.671 597.172 581.506 615.32 C 552.904 634.43 508.4 656.8 479.601 662.27 C 450.802 667.741 406.035 675.271 376.268 666.703 C 346.502 658.135 304.013 639.379 281.932 622.812 C 266.222 611.025 152.48 500.749 152.48 500.749 L 322.053 554.192 L 386.575 575.046 L 351.475 584.75 L 182.774 499.577 C 182.774 499.577 162.917 503.065 167.748 535.341 C 170.62 554.535 203.42 596.233 204.211 608.724 C 204.471 612.84 188.039 604.65 187.669 610.05 C 186.925 620.907 222.707 635.256 208.066 636.337 C 191.921 637.528 218.392 663.826 202.256 655.458 C 176.012 641.848 163.057 678.453 196.647 680.303 C 206.58 680.851 179.748 688.711 183.711 710.501 C 218.839 698.402 189.29 709.46 178.053 739.461 C 171.703 756.415 160.566 778.787 159.623 792.707"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export const makeEbzLoadingProps = propsFactory(
  {
    id: String,
    title: String,
    desc: String,
    size: {
      type: [String, Number],
      default: 64,
    },
    width: {
      type: [String, Number],
      default: "1em",
    },
    height: {
      type: [String, Number],
      default: "1em",
    },
  },
  "ebz-loading"
);
</script>

<script lang="ts" setup>
import { coercePixel } from "@/common/coercion";
import { propsFactory } from "@/common/props";
import { getUid } from "@/common/uid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps(makeEbzLoadingProps());

const { t } = useI18n({ useScope: "global" });

const id = computed(() => props.id || `ebz-loading-${getUid()}`);
const titleId = computed(() => `${id.value}-title`);
const descId = computed(() => `${id.value}-desc`);

const ariaTitle = computed(() => props.title || t("general.loading"));
const ariaLabelledBy = computed(() =>
  [titleId.value, ...(props.desc ? [descId.value] : [])].join(" ")
);
</script>

<style lang="scss">
svg.ebz-loading {
  > g {
    fill: none;
    stroke: var(--ebz-c-selective-yellow);
    stroke-width: 45;
    stroke-linecap: round;
    stroke-linejoin: round;
    paint-order: stroke fill markers;
    stroke-dasharray: 105;

    > path {
      stroke-dashoffset: 105;
      animation: ebz-loading-arrow 1.5s linear infinite;

      &:nth-child(2) {
        animation-delay: 0.75s;
      }
    }
  }
}

@keyframes ebz-loading-arrow {
  0% {
    stroke-dashoffset: 105;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -105;
  }
}
</style>
