import { getCurrentInstance, type ComponentInternalInstance } from "vue";

let _uid = 0;
let _map = new WeakMap<ComponentInternalInstance, number>();
export function getUid() {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error(`[getUid] must be called in a setup function`);
  }

  if (_map.has(vm)) {
    return _map.get(vm)!;
  } else {
    const uid = _uid++;
    _map.set(vm, uid);
    return uid;
  }
}
getUid.reset = () => {
  _uid = 0;
  _map = new WeakMap();
};
