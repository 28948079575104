import { ViteSSG } from "vite-ssg";
import type { UserModule } from "./types";
import App from "./App.vue";
import router from "./router";

import "@unocss/reset/tailwind.css";
import "./styles/main.scss";
import "uno.css";
import { getUid } from "./common/uid";

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(App, router, (ctx) => {
  // install all modules under `modules/`
  Object.values(
    import.meta.glob<{ install: UserModule }>(
      ["./modules/*.ts", "./modules/*/index.ts"],
      {
        eager: true,
      }
    )
  ).forEach((i) => i.install?.(ctx));

  getUid.reset();
});
