import { BASE_URL } from "./constants";

export function join(...pathOrUrl: string[]) {
  let output = "";
  let hash = "";
  const params = new URLSearchParams();

  for (const urlPart of pathOrUrl) {
    let url: URL;
    try {
      try {
        url = new URL(urlPart, output);
      } catch {
        url = new URL(urlPart);
      }

      output = url.origin + url.pathname;
    } catch {
      url = new URL(urlPart, "http://localhost");

      if (urlPart.startsWith("/")) {
        output = urlPart;
      } else {
        if (!output.endsWith("/")) {
          output += "/";
        }
        output += urlPart;
      }
    }

    if (url.search) {
      for (const [key, value] of url.searchParams.entries()) {
        params.append(key, value);
      }
    }

    if (url.hash) {
      hash = url.hash;
    }
  }

  const search = params.toString();
  return `${output}${search ? `?${search}` : ""}${hash}`;
}

export function absoluteUrl(...pathOrUrl: string[]) {
  return join(BASE_URL, ...pathOrUrl);
}
