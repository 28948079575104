import { ref, watch, type Ref } from "vue";
import {
  createGlobalState,
  tryOnScopeDispose,
  type CreateGlobalStateReturn,
} from "@vueuse/core";

export const useAppStore: CreateGlobalStateReturn<{
  loaded: Ref<boolean>;
  onLoaded: (callback: () => void) => () => void;
  waitLoaded: () => Promise<void>;
}> = createGlobalState(() => {
  const loaded = ref(false);
  const callbacks = new Set<() => void>();

  watch(
    () => loaded.value,
    (value) => {
      if (value) {
        callbacks.forEach((callback) => callback());
      }
    },
    { immediate: true, flush: "post" }
  );

  const onLoaded = (callback: () => void) => {
    callbacks.add(callback);

    if (loaded.value) {
      callback();
    }

    const dispose = () => {
      callbacks.delete(callback);
    };

    tryOnScopeDispose(dispose);

    return dispose;
  };

  tryOnScopeDispose(() => {
    callbacks.clear();
  });

  const waitLoaded = () => {
    return new Promise<void>((resolve) => {
      const dispose = onLoaded(() => {
        resolve();
        dispose();
      });
    });
  };

  return { loaded, onLoaded, waitLoaded };
});
