<template>
  <EbzRgb class="ebz-app" v-bind="$props">
    <RouterLink class="ebz-skip-to-content" to="#content" tabindex="0">{{
      t("skip-to-content")
    }}</RouterLink>

    <slot />
  </EbzRgb>
</template>

<script setup lang="ts">
import { coercePixel } from "@/common/coercion";
import { getScrollbarWidth } from "@/common/scroll";
import { nextTick, onMounted, onScopeDispose } from "vue";
import { useI18n } from "vue-i18n";
import EbzRgb, { makeEbzRgbProps } from "../EbzRgb/EbzRgb.vue";

defineProps(
  makeEbzRgbProps({
    zoom: 13,
  })
);

const { t } = useI18n();

onMounted(() => {
  nextTick(() => {
    document.documentElement.style.setProperty(
      "--ebz-scrollbar-offset",
      coercePixel(getScrollbarWidth())
    );
  });
});

onScopeDispose(() => {
  document.documentElement.style.removeProperty("--ebz-scrollbar-offset");
});
</script>

<style lang="scss">
.ebz-app {
  min-width: 100vw;
  min-height: 100vh;
}

.ebz-skip-to-content {
  position: absolute;
  top: 4px;
  left: 4px;
  background: var(--ebz-c-white);
  color: var(--ebz-c-black);
  padding: 0 0.2rem;
  border-radius: 0.25rem;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  z-index: -1;
  transition: all 0.1s ease-in;
  font-size: 0.8rem;
  line-height: 1.1;

  &:focus {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    z-index: 1500;
  }
}
</style>

<i18n lang="yaml">
pt-BR:
  skip-to-content: Pular para o conteúdo
</i18n>
