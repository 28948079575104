head:
  title: "@:company.name - Especialista em soluções game-based"
  description: "Revolucionamos a conexão e destravamos oportunidades entre marcas e suas comunidades-alvo utilizando o universo de games. Somos game-based."
  image_alt: "Um pôster rasgado com alguns rabiscos e fundo RGB exibindo o logotipo da eBrainz"
  twitter_site: "{'@'}ebrainz_"
general:
  loading: Carregando...
  loading_page: Carregando página, por favor aguarde...
company:
  name: eBrainz
  email: contato{'@'}ebrainz.com.br
policy:
  privacy: Política de privacidade
footer:
  copy: 2022 &copy; @:company.name{','} Todos os direitos reservados.
social_links:
  - icon: instagram
    label: Instagram
    url: https://www.instagram.com/ebrainz_/
  - icon: youtube
    label: YouTube
    url: https://www.youtube.com/c/eBrainz
  - label: Twitter
    url: https://twitter.com/ebrainz_
  - label: Facebook
    url: https://www.facebook.com/ebrainz/
form:
  validate:
    messages:
      phone: Número de telefone inválido
  mask:
    phone:
      mask:
        - "(##) #####-####"
        - "(##) ####-####"
