export type ColorRGB = [number, number, number];
export type ColorInput = string | number | ColorRGB;
export type ColorScaleFn = (factor: number) => ColorRGB;

export function rgbToHex(rgb: ColorRGB): string {
  return (
    "#" +
    Array.from({ length: 3 }, (_, i) =>
      (rgb[i] ?? 0).toString(16).padStart(2, "0")
    ).join("")
  );
}

export function numberToRgb(value: number): ColorRGB {
  if (value < 0 || value > 0xffffff) {
    throw new Error(`Invalid color value: ${value}`);
  }
  const b = value % 256;
  const g = ((value - b) / 256) % 256;
  const r = (value - b) / 256 ** 2 - g / 256;
  return [r, g, b];
}

export function hexToRgb(hex: string): ColorRGB {
  hex = hex.trim();
  if (hex.startsWith("#")) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = Array.from(hex)
      .map((v) => v + v)
      .join("");
  }
  if (hex.length !== 6) {
    throw new Error(`Invalid HEX value: ${hex}`);
  }
  return numberToRgb(parseInt(hex, 16));
}

export function parseColor(color: ColorInput): ColorRGB {
  if (Array.isArray(color)) {
    return color;
  } else if (typeof color === "string") {
    return hexToRgb(color);
  } else if (typeof color === "number") {
    return numberToRgb(color);
  } else {
    throw new Error(`Invalid color value: ${color}`);
  }
}

export function mixColors(
  color1: ColorInput,
  color2: ColorInput,
  factor = 0.5
): ColorRGB {
  const [r1, g1, b1] = parseColor(color1);
  const [r2, g2, b2] = parseColor(color2);
  const r = r1 + (r2 - r1) * factor;
  const g = g1 + (g2 - g1) * factor;
  const b = b1 + (b2 - b1) * factor;
  return [r, g, b];
}

export function colorScale(colors: ColorInput[]): ColorScaleFn {
  const colorList = colors.map(parseColor);
  return (factor: number) => {
    if (factor <= 0) return colorList[0];
    if (factor >= 1) return colorList[colorList.length - 1];

    const index = (colorList.length - 1) * factor;
    const lowerIndex = Math.floor(index);
    const upperIndex = Math.ceil(index);
    const lowerColor = colorList[lowerIndex];
    const upperColor = colorList[upperIndex];

    const weight = index - lowerIndex;

    return mixColors(lowerColor, upperColor, weight);
  };
}
