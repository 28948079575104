export function coercePixel(value: string): string;
export function coercePixel(value: number): string;
export function coercePixel(value: undefined): undefined;
export function coercePixel(
  value: string | number | undefined
): string | undefined;
export function coercePixel(value: string | number | undefined) {
  return typeof value === "undefined"
    ? undefined
    : !isNaN(Number(value))
    ? `${value}px`
    : value;
}

export const BOOLEAN_TRUE_VALUES = new Set([
  "true",
  "1",
  "yes",
  "y",
  "on",
  "enabled",
]);
export function coerceBoolean(value: any): boolean {
  if (typeof value === "string") {
    value = value.toLowerCase().trim();
    return BOOLEAN_TRUE_VALUES.has(value);
  }
  return !!value;
}
