import { useI18nMessages } from "./useI18nMessages";

export interface SocialLink {
  label: string;
  url: string;
  icon?: string;
}

export function useSocialLinks() {
  return useI18nMessages<SocialLink[]>("social_links", { useScope: "global" });
}
