import { coerceBoolean } from "@/common/coercion";
import type { UserModule } from "@/types";
import Rollbar from "rollbar";
import { BASE_URL } from "@/common/constants";

const hostname = new URL(BASE_URL).hostname;
const enabled = coerceBoolean(import.meta.env.VITE_ROLLBAR_ENABLED);

export const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: import.meta.env.MODE,
  ignoreDuplicateErrors: true,
  enabled,
  hostSafeList: [hostname],
  payload: {
    client: {
      javascript: {
        code_version: __GIT_REF__,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

export const install: UserModule = ({ app }) => {
  app.config.errorHandler = (err: any, vm, info) => {
    const component = vm?.$options;

    if (import.meta.env.DEV) {
      console.error(err, component, info);
    } else {
      console.error(err);
    }
    try {
      rollbar.error(err, {
        component,
        info,
      });
    } catch (e) {
      console.error("Rollbar error reporting failed:", e);
    }
  };
  app.config.warnHandler = (msg, vm, trace) => {
    const component = vm?.$options;

    try {
      rollbar.warn(msg, {
        component,
        trace,
      });
    } catch (e) {
      console.error("Rollbar warn reporting failed:", e);
    }

    if (import.meta.env.DEV) {
      console.warn(msg, component, trace);
    }
  };
  app.config.globalProperties.$rollbar = rollbar;
};

declare module "vue" {
  interface ComponentCustomProperties {
    $rollbar: Rollbar;
  }
}
