import { join } from "./url";

export const BASE_URL = join(
  import.meta.env.VITE_HOST,
  import.meta.env.BASE_URL
);

export const BASE_API_URL = join(BASE_URL, import.meta.env.VITE_API_URL);

export const BASE_ADMIN_URL = join(BASE_URL, import.meta.env.VITE_ADMIN_URL);
