import type { Theme } from "unocss/preset-mini";

export const theme = {
  fontFamily: {
    body: '"Zabal", "Roboto", sans-serif',
    heading: '"MADE TOMMY", "Roboto", sans-serif',
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    selectiveYellow: "#FFBB00",
    magenta: "#EC3AE3",
    magenta65: "rgba(236, 58, 227, 0.65)",
    mediumSpringGreen: "#00FFAE",
    blue: "#0054FF",
    radicalRed: "#FF0059",
    blueRYB: "#3838FF",
  },
  breakpoints: {
    xs: "0",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px",
    xxl: "2560px",
  },
  typography: {
    h1: {
      "font-family": "$fontFamily.heading",
      "font-weight": "700",
      "font-size": "3rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    h2: {
      "font-family": "$fontFamily.heading",
      "font-weight": "700",
      "font-size": "2.5rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    h3: {
      "font-family": "$fontFamily.heading",
      "font-weight": "600",
      "font-size": "2rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    h4: {
      "font-family": "$fontFamily.heading",
      "font-weight": "600",
      "font-size": "1.75rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    h5: {
      "font-family": "$fontFamily.heading",
      "font-weight": "500",
      "font-size": "1.5rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    h6: {
      "font-family": "$fontFamily.heading",
      "font-weight": "500",
      "font-size": "1.25rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    "subtitle-1": {
      "font-family": "$fontFamily.heading",
      "font-weight": "400",
      "font-size": "1rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    "subtitle-2": {
      "font-family": "$fontFamily.heading",
      "font-weight": "500",
      "font-size": "0.875rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    "body-1": {
      "font-family": "$fontFamily.body",
      "font-weight": "400",
      "font-size": "1rem",
      "line-height": "1.5",
      "margin-bottom": "1rem",
    },
    "body-2": {
      "font-family": "$fontFamily.body",
      "font-weight": "400",
      "font-size": "0.875rem",
      "line-height": "1.5",
      "margin-bottom": "1rem",
    },
    caption: {
      "font-family": "$fontFamily.body",
      "font-weight": "400",
      "font-size": "0.75rem",
      "line-height": "1.2",
      "margin-bottom": "0.5rem",
    },
    overline: {
      "font-family": "$fontFamily.body",
      "font-weight": "500",
      "font-size": "0.75rem",
      "line-height": "1.2",
      "text-transform": "uppercase",
      "margin-bottom": "0.5rem",
    },
  },
};

export type AppTheme = Theme & typeof theme;
